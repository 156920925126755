import gql from "graphql-tag";

import { checkoutAddressFragment, checkoutFragment } from "./checkout";

export const userFragment = gql`
  ${checkoutAddressFragment}
  ${checkoutFragment}
  fragment User on User {
    id
    email
    firstName
    lastName
    isStaff
    birthDate
    phone
    ridingStyle
    gender
    dateJoined
    defaultShippingAddress {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    addresses {
      ...Address
    }
    checkout {
      ...Checkout
    }
    metadata {
      key
      value
    }
  }
`;
