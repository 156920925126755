import { userFragment } from "../fragments/auth";
import { gql } from "@apollo/client";

export const changeUserPassword = gql`
  mutation PasswordChange($newPassword: String!, $oldPassword: String!) {
    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
      errors {
        field
        message
      }
    }
  }
`;

export const accountRegisterMutation = gql`
  mutation RegisterAccount(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $redirectUrl: String!
  ) {
    accountRegister(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        redirectUrl: $redirectUrl
      }
    ) {
      errors {
        field
        message
      }
      requiresConfirmation
    }
  }
`;
export const accountUpdate = gql`
  ${userFragment}
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      errors {
        field
        message
      }
      user {
        ...User
      }
    }
  }
`;

export const forgotPasswordMutation = gql`
  mutation ForgotPassword($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
      accountErrors {
        field
        code
        message
      }
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation ResetPassword($token: String!, $email: String!, $password: String!) {
    setPassword(token: $token, email: $email, password: $password) {
      accountErrors {
        field
        code
        message
      }
    }
  }
`;
