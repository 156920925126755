"use client";
import React, { useMemo, useState } from "react";
import { CredentialsProvider } from "@/lib/saleor/reactSaleor/components/CredentialsProvider/CredentialsProvider";
import { SaleorContext } from "@/lib/saleor/reactSaleor/context";
import { useApollo } from "@/lib/saleor/apollo";
import { SaleorAPI } from "@/lib/saleor/api";
import { SaleorManager } from "@/lib/saleor";
import { ApolloProvider } from "@apollo/client/react/context";
import { IProps } from "./types";

const SALEOR_CONFIG = {
  apiUrl: process.env.NEXT_PUBLIC_API_URI,
  loadOnStart: {
    checkout: false,
  },
};

export function SaleorProvider({ children, config }) {
  const [context, setContext] = useState(null);
  const client = useApollo();

  useMemo(() => {
    const manager = new SaleorManager(client, config);

    manager.connect(saleorAPI => setContext({ ...saleorAPI }));

    return manager;
  }, [client, config]);
  return (
    <ApolloProvider client={client}>
      <SaleorContext.Provider value={context}>
        {context ? <CredentialsProvider>{children}</CredentialsProvider> : <></>}
      </SaleorContext.Provider>
    </ApolloProvider>
  );
}
