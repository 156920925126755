import Cookies from "universal-cookie";
const cookies = new Cookies();

export const createCookie = (name, value, expires) => {
  cookies.remove(name, { path: "/" });
  const current = new Date();
  const lastYear = new Date();
  const option = {};
  if (expires) {
    option.expires = new Date(lastYear.setFullYear(current.getFullYear() + expires));
  }
  cookies.set(name, value, {
    ...option,
    path: "/",
  });
};

export const getCookie = key => {
  return cookies.get(key);
};

export const removeCookie = key => {
  return cookies.remove(key, { path: "/" });
};
