import { ApolloClient } from "@apollo/client";

import { Config } from "../types";
import { APIProxy } from "./APIProxy";
import { UserDetails_me } from "@/queries/gqlTypes/UserDetails";
import { SaleorCheckoutAPI } from "./Checkout";
import { SaleorCartAPI } from "./Cart";
import { defaultConfig } from "../config";
import { CheckoutRepositoryManager, LocalRepository } from "@/lib/saleor/repository";
import { NetworkManager } from "@/lib/saleor/network";
import { SaleorState } from "@/lib/saleor/state";
import { JobsManager } from "@/lib/saleor/jobs";
import { CheckoutData, CheckoutHandler } from "./Checkout/types";

export const handleMergeGuestCheckoutMetadata = (
  checkoutId: string,
  updateCheckoutMetadata: (checkoutId: string, metadata: any) => Promise<any>
): void => {
  const guestCheckoutMetadataString = localStorage.getItem("guest_checkout_metadata");
  if (!guestCheckoutMetadataString) return;

  const guestCheckoutMetadata: { key: string; value: string }[] = JSON.parse(guestCheckoutMetadataString);
  const giftCardGuestMetadata = guestCheckoutMetadata.find(item => item.key === "giftCard");

  if (!giftCardGuestMetadata) return;

  const checkoutMetadataString = localStorage.getItem("checkout_metadata");
  if (!checkoutMetadataString) return;

  const checkoutMetadata: { key: string; value: string }[] = JSON.parse(checkoutMetadataString);
  const giftCardMetadata = checkoutMetadata.find(item => item.key === "giftCard");

  if (!giftCardMetadata) return;

  const newGuestValue = JSON.parse(giftCardGuestMetadata.value);
  const currentValue = JSON.parse(giftCardMetadata.value);

  if (currentValue.length > 0) {
    // Merge value
    newGuestValue.forEach((guestItem: any) => {
      const value = currentValue.find((item: any) => item?.productID === guestItem.productID);
      if (value) {
        value.recipients.push(...guestItem.recipients);
      } else {
        currentValue.push(guestItem);
      }
    });

    giftCardMetadata.value = JSON.stringify(currentValue);
  } else {
    giftCardMetadata.value = giftCardGuestMetadata.value;
  }

  // Remove guest_checkout_metadata from Local Storage
  localStorage.removeItem("guest_checkout_metadata");

  // Construct new checkout metadata
  const newCheckoutMetadata = checkoutMetadata.map(item => ({
    key: item.key,
    value: item.key === "giftCard" ? giftCardMetadata.value : item.value,
  }));

  // Call API to update metadata and then update Local Storage
  updateCheckoutMetadata(checkoutId, newCheckoutMetadata).then(res => {
    const metadataObject = res.data.updateMetadata;
    if (metadataObject?.errors?.length > 0) {
      console.log("Update metadata failed");
    } else {
      localStorage.setItem("checkout_metadata", JSON.stringify(metadataObject?.item?.metadata));
    }
  });
};

const handleMergeGuestCheckout = async (
  me: UserDetails_me,
  { addItem, createCheckout, updateCheckoutMetadata }: CheckoutHandler
) => {
  const checkout = me.checkout;
  const guestDataCheckout = localStorage.getItem("guest_data_checkout");
  const checkoutData: CheckoutData = guestDataCheckout ? JSON.parse(guestDataCheckout) : null;
  //Check current cart has line items but not be created yet
  if (checkoutData?.lines?.length && !checkoutData.token) {
    //Then, update checkout if user has checkout, add item step by step
    if (checkout) {
      for (const line of checkoutData.lines) {
        await addItem(line.variant.id, line.quantity);
      }
      localStorage.removeItem("guest_data_checkout");
    }
    //Else, user hasn't checkout, then create new checkout from guest checkout
    else {
      const lines = checkoutData.lines.map(line => ({
        variantId: line.variant.id,
        quantity: line.quantity,
      }));
      createCheckout({ email: me.email, lines }).then(({ data, dataError }: any) => {
        if (data) {
          console.log("Create new checkout");
          handleMergeGuestCheckoutMetadata(data.id, updateCheckoutMetadata);
        }
        if (dataError) {
          console.error("Cannot create checkout when guest logged in", dataError);
        }

        localStorage.removeItem("guest_data_checkout");
      });
    }
  }
};

export class SaleorAPI {
  checkout: SaleorCheckoutAPI;
  cart: SaleorCartAPI;

  /**
   * @deprecated Please do not use it anymore. Reference to API Proxy will be removed in future.
   * Now it just exists for legacy React hooks, which also will be removed.
   */
  legacyAPIProxy: APIProxy;

  constructor(client: ApolloClient<any>, apiProxy: APIProxy, config?: Config, onStateUpdate?: () => any) {
    this.legacyAPIProxy = apiProxy;
    const finalConfig = {
      ...defaultConfig,
      ...config,
      loadOnStart: {
        ...defaultConfig.loadOnStart,
        ...config?.loadOnStart,
      },
    };

    const { loadOnStart } = finalConfig;

    const repository = new LocalRepository();
    const networkManager = new NetworkManager(client);
    const saleorState = new SaleorState(repository, networkManager);
    const checkoutRepositoryManager = new CheckoutRepositoryManager(repository, saleorState);
    const jobsManager = new JobsManager(repository, networkManager);

    if (onStateUpdate) {
      saleorState.subscribeToNotifiedChanges(onStateUpdate);
    }

    this.checkout = new SaleorCheckoutAPI(saleorState, loadOnStart.checkout, jobsManager);
    this.cart = new SaleorCartAPI(
      checkoutRepositoryManager,
      networkManager,
      saleorState,
      loadOnStart.cart,
      jobsManager
    );

    /**
     * This event listener is called after user logged in
     */
    this.legacyAPIProxy.attachAuthListener(authenticated => {
      console.log("====Attached Auth listener====");
      if (!authenticated) {
        repository.setCheckout({});
        repository.setPayment({});
        repository.setJobs(null);
      } else {
        //Process merge or create guest checkout when user logged in.
        //Get data_checkout from local repository
        const checkout = repository.getCheckout();

        //Should clone guest checkout from data_checkout
        localStorage.setItem("guest_data_checkout", JSON.stringify(checkout));

        const onDataUpdate = (me: UserDetails_me | null) => {
          //Set checkout if user logged in
          console.log("====User logged in====");

          if (me) {
            //Set metadata user after user login
            localStorage.setItem(
              "user_metadata",
              JSON.stringify({
                metadata: me.metadata,
                email: me.email,
              })
            );

            //Create handler
            const addItem = this.cart.addItem;
            const createCheckout = this.checkout.createCheckout;
            const updateCheckoutMetadata = this.checkout.updateCheckoutMetadata;
            const handler: CheckoutHandler = { addItem, createCheckout, updateCheckoutMetadata };

            //Set current user's cart
            repository.setCheckout(me.checkout || {});

            //Handle guest cart after login to user
            handleMergeGuestCheckout(me, handler);

            //Set metadata checkout after user login
            const metadataEmpty = {
              __typename: "MetadataItem",
              key: "giftCard",
              value: "[]",
            };
            let newMetadata = [];
            if (me.checkout?.metadata?.length > 0) {
              newMetadata = me.checkout.metadata;
              if (me.checkout.metadata.every((e: any) => e.key != "giftCard")) {
                // metadata does not contain giftCard
                newMetadata.push(metadataEmpty);
              }
            } else newMetadata.push(metadataEmpty);
            localStorage.setItem("checkout_metadata", JSON.stringify(newMetadata));

            //Handle guest cart after login to user
            if (me.checkout?.id) handleMergeGuestCheckoutMetadata(me.checkout.id, updateCheckoutMetadata);
          }
        };
        this.legacyAPIProxy.getUserDetails(null, { onUpdate: onDataUpdate, fetchPolicy: "network-only" });
      }
    });
  }
}
