import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { getCookie, removeCookie, createCookie } from "../../services/cookie";

export function getAuthToken(): string | null {
  try {
    return getCookie("token");
  } catch {
    return null;
  }
}

export function setAuthToken(token: string, expire?: number) {
  createCookie("token", token, expire);
  const authEvent = new Event("auth");
  dispatchEvent(authEvent);
}

export function removeAuthToken() {
  removeCookie("token");
}

export function clearStorage(): void {
  removeCookie("token");
  localStorage.clear();
}

export function fireSignOut(client?: ApolloClient<any>): void {
  clearStorage();
  if (navigator.credentials && navigator.credentials.preventSilentAccess) {
    navigator.credentials.preventSilentAccess();
  }
  if (client) {
    client.resetStore();
  }
}

interface ResponseError extends ErrorResponse {
  networkError?: Error & {
    statusCode?: number;
    bodyText?: string;
  };
}

// possibly remove callback here and use event emitter
export const invalidTokenLinkWithTokenHandler = (
  tokenExpirationCallback: () => void
): {
  link: ApolloLink;
} => {
  const link = onError((error: ResponseError) => {
    const isTokenExpired = error.graphQLErrors?.some(
      error => error.extensions?.exception?.code === "JSONWebTokenExpired"
    );
    if (isTokenExpired || (error.networkError && error.networkError.statusCode === 401)) {
      tokenExpirationCallback();
    }
  });
  return { link };
};

export const authLink = setContext((_, context) => {
  const authToken = getAuthToken();
  if (authToken) {
    return {
      ...context,
      headers: {
        ...context.headers,
        Authorization: authToken ? `JWT ${authToken}` : null,
      },
    };
  } else {
    return context;
  }
});
