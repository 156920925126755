import { ApolloClient, ObservableQuery, QueryOptions as ApolloQueryOptions } from "@apollo/client";

import { RequireOnlyOne } from "../tsHelpers";
import { UserDetails } from "./gqlTypes/UserDetails";

import * as User from "./user";
import * as Orders from "./orders";
import { OrderByToken, OrderByTokenVariables } from "./gqlTypes/OrderByToken";
import { OrdersByUser, OrdersByUserVariables } from "@/mutations/gqlTypes/OrdersByUser";

type QueryOptions<T = {}> = T extends { [n: string]: never }
  ? Omit<ApolloQueryOptions<{}>, "query">
  : RequireOnlyOne<Omit<ApolloQueryOptions<T>, "query">, "variables">;

// TODO: Add ability to pass custom fragments to queries
export const QUERIES = {
  UserDetails: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: QueryOptions<null>
  ): ObservableQuery<UserDetails, any> =>
    client.watchQuery({
      query: User.getUserDetailsQuery,
      ...options,
    }),
  OrderDetails: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: QueryOptions<OrderByTokenVariables>
  ): ObservableQuery<OrderByToken, any> =>
    client.watchQuery({
      query: User.orderDetailsByTokenQuery,
      ...options,
    }),
  OrdersByUser: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: QueryOptions<OrdersByUserVariables>
  ): ObservableQuery<OrdersByUser, any> =>
    client.watchQuery({
      query: Orders.ordersByUser,
      ...options,
    }),
};

export type QUERIES = typeof QUERIES;
